import axios from "axios";
import React, { useContext, useRef } from "react";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import { useState } from "react";
import { selectOption } from "../../common/common-data/alphabets";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CreateDataApi,
  GetDataByIdApi,
  GetListApi,
  UpdateDataApi,
} from "../../common/api/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import AppContext from "../../appContext";
import { CheckAccess } from "../../common/utils/roleAccess";
import { convertToSlug } from "../../common/count-words-and-characters";
import { toast } from "react-toastify";
import saveToLogs from "../../common/utils/saveToLogs";

const usePhotoStoryhook = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const navigate = useNavigate();
  let { id } = useParams();
  const statusRef = useRef(`open`);

  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const { appData, setAppData } = useContext(AppContext);

  const [populateManageData, setPopulateManageData] = useState(false)
  const [isEditingById, setIsEditingById] = useState('')
  const [editEnabled, setEditEnabled] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [storiesList, setStoriesList] = useState([]);
  const [submittedPage, setSubmittedPage] = useState(null);
  const [showTimeline, setShowTimeline] = useState(false);
  const [timelines, setTimelines] = useState([]);

  const [pageList, setPageList] = useState([]);
  const [sections, setSections] = useState([]);
  const [includeSectionList, setIncludeSectionList] = useState(selectOption);
  const [selectedIncludeSection, setSelectedIncludeSection] = useState([]);
  const [authorList, setAuthorList] = useState(selectOption);
  const [selectedAuthorList, setSelectedAuthorList] = useState([
    {
      id: user?._id,
      label: user?.name,
      value: user?.name,
    },
  ]);

  const [tagsList, setTagsList] = useState(selectOption);
  const [selectedTagsList, setSelectedTagsList] = useState([]);
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [storyStatus, setStoryStatus] = useState("");
  const [firstApiCalled, setFirstApiCalled] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    sub_title: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",

    social_title: "",
    message: "",
    social_description: "",

    status: "Active",
    showHeader: false,
    showFooter: false,
    isSponsered_story: false,
    isCard_shareable: false,
    isStory_promotional_message: false,
  });
  // State to manage cards and sub-cards
  const [cards, setCards] = useState([
    {
      sub_card: [
        {
          type: "Image",
          title: "",
          attributes: "",
          actionUrl: "",
          description: "",
          isExternal: false,
          noFollow: false,
          question: "",
          question_attributes: "",
          answer: "",
          answer_attributes: "",
          summary: "",
          images: "",
          file_id: "",
          img_caption: "",
          img_attribution: "",
          link_story: "",
        },
        {
          type: "Text",
          title: "",
          attributes: "",
          actionUrl: "",
          description: "",
          isExternal: false,
          noFollow: false,
          question: "",
          question_attributes: "",
          answer: "",
          answer_attributes: "",
          summary: "",
          images: "",
          file_id: "",
          img_caption: "",
          img_attribution: "",
          link_story: "",

          img_alt: "",
          img_credit: "",
          link_story: "",
          story: "",
        },
      ],
    },
  ]);

  const HandleSelectChange = (type, e) => {
    if (type === "tags") {
      setValue("tags", e);
      setSelectedTagsList(e);
    } else if (type === "sections") {
      setValue("sections", e);
      setSelectedIncludeSection(e);
    } else if (type === "authors") {
      setValue("authors", e);
      setSelectedAuthorList(e);
    }
  };
  const [socialCard, setSocialCard] = useState([
    {
      social_share_message: "",
      is_raftaar_netlabs: false,
      is_raftaar: false,
      is_scheduled: false,
      scheduled_date: "",
    },
  ]);

  const getSectionParentChain = async (section_name) => {
    const url = `${config.apiUrl}/section?sectionName=${section_name}`
    const response = await fetch(url)
    const sectionData = await response.json()

    const section = sectionData?.sections?.[0]

    if (section?.parent_section?.length > 0) {
      return await getSectionParentChain(section?.parent_section?.[0]) + " > " + capitaliseSentence(section?.section_name)
    }
    else {
      return await capitaliseSentence(section?.section_name)
    }
  }

  const handleAddElement = (type) => {
    const newElement = {
      social_share_message: "",
      is_raftaar_netlabs: false,
      is_raftaar: false,
      is_scheduled: false,
      scheduled_date: "",
    };
    setSocialCard([...socialCard, newElement]);
  };
  const handleDeleteElement = (index) => {
    const updateSocialCard = [...socialCard];
    updateSocialCard.splice(index, 1);
    setSocialCard(updateSocialCard);
  };
  const handleSocialCardChange = (index, field, value) => {
    const updateSocialCard = [...socialCard];
    updateSocialCard[index][field] = value;
    setSocialCard(updateSocialCard);
  };

  const capitaliseSentence = (sentence) => {
    if (!sentence) return null
    sentence = sentence.replace('-', ' ')
    let capitalizedString = sentence.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
    return capitalizedString
  }

  const fetchOurSection = (inputValue) => {
    axios
      .get(`${config.apiUrl}/section/with/parent-chain`, {
        params: {
          status: "Active",
          ...(inputValue && { searchKeyWord: inputValue })
        },
      })
      .then((response) => {
        let arr = [];
        let activeSectionArr = [];
        if (response.data?.sections.length > 0) {
          let result = response?.data?.sections?.map(async (sec) => {
            if (sec?.status === "Active") {
              let sectionString = capitaliseSentence(sec?.section_name)
              if (sec?.parent_section && sec?.parent_section?.length > 0) {
                let subSectionString = sec?.parent_section
                  ?.map((subSec) => capitaliseSentence(subSec))
                  .join(" > ");
                // sectionString += " > " + subSectionString;
                sectionString = subSectionString + " > " + sectionString;
              }

              let parent_section = sec?.parent_section?.[0]

              if (parent_section) {
                // const parentChain = await getSectionParentChain(sec.section_name)
                sectionString = sec?.parent_chain
              }

              arr?.push({
                label: sectionString,
                value: sec?._id,
              });

              activeSectionArr?.push(sec);

              setSections(activeSectionArr);
              return sectionString;
            }
          });
          setIncludeSectionList(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact requests:", error);
      });
  };
  const FetchTagsList = async (tagName) => {
    try {
      let query = (tagName?.trim() !== "") ? { name: tagName } : {}
      const response = await axios
        .get(`${config.apiUrl}/${apiEndpoints.tags}`, {
          params: { ...query },
        })
        .then((response) => {
          let arr = [];
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              arr?.push({
                label: element?.name,
                value: element?._id,
              });
              setTagsList(arr);
            });
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAuthorList = async () => {
    try {
      const response = await axios
        .get(`${config.apiUrl}/${apiEndpoints?.user}`)
        .then((response) => {
          let arr = [];

          if (response?.data?.users.length > 0) {
            response?.data?.users.forEach((element) => {
              if (element?.user_role?.name === "author" || element?.user_role?.name === "Admin" || element?.user_role?.name === "Editor" || element?.user_role?.name === "Guest Contributor") {
                arr?.push({
                  label: element?.name,
                  value: element?.name,
                  id: element?._id,
                });
              }
            });
            setAuthorList(arr);
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   let path = window?.location?.pathname

  //   if (path?.includes('create')) {
  //     fetchOurSection();
  //     FetchTagsList();
  //   }
  //   fetchAuthorList()
  // }, []);

  useEffect(() => {
    if (populateManageData) {
      fetchOurSection();
      FetchTagsList();
      fetchAuthorList()
      setPopulateManageData(null)
    }
  }, [populateManageData])

  const HandleUploadImage = async (files) => {
    let data = {};

    try {
      if (files?.length > 0) {
        const formData = new FormData();
        data.status = "Active";
        const res = await axios.post(
          `${config.apiUrl}/${apiEndpoints.upload}`,
          { files: files[0] },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res?.data?.length) {
          setValue("file_name", res?.data[0]?.file_name);
          setValue("file_path", res?.data[0]?.file_path);
          setValue("file_Id", res?.data[0]?._id);
          // setImgUrl(`${config?.apiUrl}/${res?.data[0]?.file_path}`);
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const [currentImgId, setCurrentImgId] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const fetchStoryById = async (_id) => {
    const response = await GetDataByIdApi(
      `${apiEndpoints.storiesById(id || _id)}`
    );
    if (response) {
      setValue("is_breaking_news", response?.is_breaking_news)
      setValue("breaking_title", response?.breaking_title)
      setValue("breaking_description", response?.breaking_description)

      setValue("title", response?.title);
      setValue("sub_title", response?.sub_title);
      setAppData({ story_Id: response?._id, status: response?.status, parent_story_id: response?.parent_story_id, child_story_id: response?.child_story_id });

      if (response.cards.length > 0) {
        setValue("cards", response?.cards);
        setCards(response?.cards);
      }
      statusRef.current = response?.status;

      if (response?.status !== 'published' && !(response?.status === 'scheduled' && response?.publishDate <= new Date().toISOString())) setEditEnabled(true)

      if (response?.is_editing_by?._id === user?._id) {
        setEditEnabled(true)
      }
      else {
        setEditEnabled(false)
      }

      setFormData({
        // seo,
        // ...response?.seo,
        meta_title: response?.seo?.meta_title,
        meta_description: response?.seo?.meta_description,
        meta_keywords: response?.seo?.meta_keywords,

        breaking_title: response?.breaking_title,
        breaking_description: response?.breaking_description,

        user: user,
        is_editing_by: response?.is_editing_by,

        status: response?.status,
        is_child_story: response?.is_child_story,
        _id: response?._id,
        story_type: response?.story_type,

        publish_date: response?.publish_date,

        //title
        title: response?.title,
        sub_title: response?.sub_title,
        //story features
        is_breaking_news: response?.is_breaking_news,
        isCard_shareable: response?.story_features?.isCard_shareable,
        isSponsered_story: response?.story_features?.isSponsered_story,
        sponsored_by: response?.story_features?.sponsored_by,
        isStory_promotional_message:
          response?.story_features?.isStory_promotional_message,
      });
      if (response?.hero_image) {
        setImgUrl(`${config?.fileUrl}/${response?.hero_image?.file_path}`);
        setValue("file_path", response?.hero_image?.file_path);
        setCurrentImgId(response?.hero_image?.file_id);
        setValue("caption", response?.hero_image?.caption);
        setValue("attribution", response?.hero_image?.attribution);
        setValue("alt", response?.hero_image?.alt);
        setValue("credit", response?.hero_image?.credit);
      }

      if (response?.sections?.length > 0) {
        setSelectedIncludeSection(response.sections);
        setValue("sections", response.sections);
      }

      if (response?.tags?.length > 0) {
        setSelectedTagsList(response.tags);
        setValue("tags", response.tags);
      }

      if (response?.authors) {
        setSelectedAuthorList(response?.authors);
      }

      setValue("authors", response?.authors);
      setValue("custom_slug", response?.custom_slug);
      setValue("slug_preview", response?.slug_preview);
      setValue("canonical_url", response?.canonical_url);

      if (response?.seo) {
        // setFormData({ ...formData, ...response?.seo });
        setValue("meta_description", response?.seo?.meta_description);
        setValue("meta_keywords", response?.seo?.meta_keywords);
        setValue("meta_title", response?.seo?.meta_title);
      }

      if (response?.social) {
        setValue("social_title", response?.social?.social_title);
        setValue("message", response?.social?.message);
        setValue("platform", response?.social?.platform);
      }

      setValue("social_description", response?.social_description);

      if (response?.social_card?.length > 0) {
        setSocialCard(response?.social_card);
      }

      HandleGetSection();
      // fetchTimeline(`${response?._id}`);
    }
  };
  const onSubmit = async (data) => {
    let httpMethod = null
    let url = null
    let logsPayload = null
    let logsStoryId = null

    try {
      data.is_breaking_news = formData.is_breaking_news;
      data.disclaimer = formData.disclaimer;
      data.hero_image = {
        file_name: data?.file_name,
        file_path: data?.file_path,
        file_id: data?.file_id,
        caption: data?.caption,

        alt: data?.alt,
        credit: data?.credit,
      };
      data.story_features = {
        isSponsered_story: formData?.isSponsered_story,
        sponsored_by: formData?.sponsored_by,
        isCard_shareable: formData?.isCard_shareable,
        isStory_promotional_message: formData?.isStory_promotional_message,
      };
      data.seo = {
        meta_title: data?.meta_title,
        meta_description: data?.meta_description,
        meta_keywords: data?.meta_keywords,
      };
      data.social = {
        social_title: data?.social_title,
        message: data?.message,
        platform: data?.platform,
      };
      data.social_card = socialCard;
      data.created_by = user?.name;
      data.story_type = `Photo Story`;
      data.status = `submitted`;
      statusRef.current = `submitted`;
      data.updated_on = new Date().toISOString()

      setLoading(true);
      if (!appData?.story_Id) {
        httpMethod = 'POST'
        url = `${apiEndpoints?.stories}`
        logsPayload = data
        logsStoryId = null

        data.is_editing_by = user?._id
        const response = await CreateDataApi(`${apiEndpoints?.stories}`, data);
        if (response) {
          const timeline = await CreateDataApi(apiEndpoints?.timeline, {
            story_id: response?._id,
            action_by: user?._id,
            action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
            action_type: `submitted`,
          });
          await fetchTimeline(`${response?._id}`);
          setLoading(false);

          setIsEditingById(user?._id)
          setFormData({ ...formData, is_editing_by: user })
          setEditEnabled(true)

          // setStoryStatus(response?.status);
          setAppData({ story_Id: response?._id, status: response?.status, parent_story_id: response?.parent_story_id, child_story_id: response?.child_story_id });
          setValue("story_id", response?._id);

          if (user?.user_role?.slug === "guest-contributor") {
            navigate("/story");
            window?.location?.reload();
          }
        }
      } else {
        httpMethod = 'PUT'
        url = `${apiEndpoints.storiesById(data?.story_id)}`
        logsPayload = data
        logsStoryId = data?.story_id

        data.is_editing_by = null

        const response = await UpdateDataApi(
          `${apiEndpoints.storiesById(data?.story_id || id)}`,
          data
        );
        if (response) {
          const timeline = await CreateDataApi(apiEndpoints?.timeline, {
            story_id: response?._id,
            action_by: user?._id,
            action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
            action_type: `submitted`,
          });
          await fetchTimeline(`${response?._id}`);
          setStoryStatus(response?.status);

          if (user?.user_role?.name === "Guest Contributor") {
            setIsEditingById(null)
            setFormData({ ...formData, is_editing_by: null })
            setEditEnabled(false)
          }

          setValue("status", response?.status);
          setStoryStatus(response?.status);

          setAppData({ story_Id: response?._id, status: response?.status, parent_story_id: response?.parent_story_id, child_story_id: response?.child_story_id });
          setLoading(false);
          if (user?.user_role?.slug === "guest-contributor") {
            navigate("/story");
            window?.location?.reload();
          }
        }
      }
    } catch (error) {
      // toast.error("Error creating page");
      const errorDetails = {
        message: error.message,
        stack: error.stack
      }

      await saveToLogs({
        source: 'onsubmit',
        payload: logsPayload,
        error_message: errorDetails,
        url: url,
        http_method: httpMethod,
        story_id: logsStoryId,
        log_type: 'photo story'
      })
    }
  };
  // U P D A T E  S T O R Y
  const onUpdate = async (data) => {
    try {
      data.hero_image = {
        file_name: data?.file_name,
        file_path: data?.file_path,
        file_id: data?.file_id,
        caption: data?.caption,

        alt: data?.alt,
        credit: data?.credit,
      };
      data.story_features = {
        isSponsered_story: formData?.isSponsered_story,
        sponsored_by: formData?.sponsored_by,
        isCard_shareable: formData?.isCard_shareable,
        isStory_promotional_message: formData?.isStory_promotional_message,
      };
      data.seo = {
        meta_title: data?.meta_title,
        meta_description: data?.meta_description,
        meta_keywords: data?.meta_keywords,
      };
      data.social = {
        social_title: data?.social_title,
        message: data?.message,
        platform: data?.platform,
      };

      data.social_card = socialCard;
      data.updated_by = user?.name;
      data.updated_on = moment().format("MMMM DD YYYY, hh:mm:ss a");
      // data.status = storyStatus ? storyStatus : "submitted";
      data.story_type = `Photo Story`;
      data.updated_on = new Date().toISOString();

      if (
        data?.sections &&
        data?.tags &&
        data?.authors &&
        data?.meta_title !== "" &&
        data?.meta_description !== ""
      ) {
        setLoading(true);
        const response = await UpdateDataApi(
          `${apiEndpoints.storiesById(id)}`,
          data
        );
        if (response) {
          const timeline = await CreateDataApi(apiEndpoints?.timeline, {
            story_id: response?._id,
            action_by: user?._id,
            action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
            action_type: `updated`,
          });
          await fetchTimeline(`${response?._id}`);

          setAppData({ story_Id: response?._id, status: response?.status, parent_story_id: response?.parent_story_id, child_story_id: response?.child_story_id });
          setLoading(false);
          setValue("story_id", response?._id);
        }
      }
    } catch (err) {
      console.log(err?.message, "error");
    }
  };
  const HandleModalDataSubmit = (data) => {
    handleClose();
  };

  const fetchOurStory = async (status, query) => {
    const response = await GetListApi(`${apiEndpoints.stories}`, {
      page: currentPage,
      pageSize: 50,
      pagination: true,
      status: status,
      isBackend: true,
      story_type: "Photo Story",
      q: query,
      authors: CheckAccess("story", "see own") ? `${user?._id}` : ``,
    });
    if (response?.stories) {
      setStoriesList(response?.stories);
      setTotalPages(response?.pages);
    }
  };
  useEffect(() => {
    let path = window?.location?.pathname
    if (!path.includes('create') && !path.includes('update'))
      fetchOurStory();
  }, [currentPage]);
  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}/stories/${id}`)
          .then((response) => {
            fetchOurStory();
          })
          .catch((error) => {
            console.error("Error deleting item:", error?.message);
          });
      }
    });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const HandleGetSection = async (e) => {
    if (statusRef.current !== "published") {
      const values = getValues();
      let convertedSlug = convertToSlug(values?.custom_slug);
      if (values?.sections?.length > 0) {
        let slugUrl = values?.sections[0]?.label
          ?.split(" > ")
          // ?.reverse()
          ?.join("/")
          ?.replace(/ /g, "-");

        setValue(
          "slug_preview",
          `${values?.sections[0]?.label
            ?.split(" > ")
            // ?.reverse()
            ?.join("/")
            ?.toLowerCase()
            ?.replace(/ /g, "-")}/${convertedSlug}`
        );
        setSlug(slugUrl?.split("/")?.reverse()?.join("/"));
      } else {
        setSlug("");
        setValue("slug_preview", "");
      }
    }
  };

  useEffect(() => {
    if (!id) {
      setEditEnabled(true)
    }
    if (id) {
      fetchStoryById();
      fetchTimeline(id);
    }
  }, [id]);

  const onUpdateStatus = async (status) => {
    const story = getValues();
    story.status = status;
    story.updated_on = new Date().toISOString()
    setStoryStatus(status);
    statusRef.current = status;
    setLoading(true);
    const response = await UpdateDataApi(
      `${apiEndpoints.storiesById(story?.story_id || id)}`,
      story
    );

    if (response) {
      const timeline = await CreateDataApi(apiEndpoints?.timeline, {
        story_id: response?._id,
        action_by: user?._id,
        action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
        action_type: status,
      });
      await fetchTimeline(`${response?._id}`);
      setLoading(false);
      setValue("story_id", response?._id);
    }
  };

  const onAutoSaveStory = async () => {
    let httpMethod = null
    let url = null
    let logsPayload = null
    let logsStoryId = null

    try {
      const storyData = getValues();
      if (!storyData?.title) {
        toast.error('please enter title')
        return
      }

      const isExistingStory = storyData?.story_id;

      storyData.is_breaking_news = formData.is_breaking_news;
      storyData.disclaimer = formData?.disclaimer;

      storyData.hero_image = {
        file_name: storyData?.file_name,
        file_path: storyData?.file_path,
        file_id: storyData?.file_id,
        caption: storyData?.caption,
        attribution: storyData?.attribution,
        alt: storyData?.alt,
        credit: storyData?.credit,
      };

      storyData.story_features = {
        isSponsered_story: formData?.isSponsered_story,
        sponsored_by: formData?.sponsored_by,
        isCard_shareable: formData?.isCard_shareable,
        isStory_promotional_message: formData?.isStory_promotional_message,
      };
      storyData.seo = {
        meta_title: storyData?.meta_title,
        meta_description: storyData?.meta_description,
        meta_keywords: storyData?.meta_keywords,
      };
      storyData.social = {
        social_title: storyData?.social_title,
        message: storyData?.message,
        platform: storyData?.platform,
      };
      storyData.social_card = socialCard;
      storyData.story_type = `Photo Story`;
      if (!id && !isExistingStory && !storyData.story_id) {
        storyData.status =
          statusRef.current !== `` ? statusRef.current : `open`;
      }
      if (
        statusRef.current === "published" ||
        statusRef.current === "retract" ||
        statusRef.current === "rejected" ||
        statusRef.current === "scheduled"
      ) {
        statusRef.current = `open`;
        storyData.status = `open`;
      }

      storyData.updated_on = new Date().toISOString()

      storyData.is_editing_by = user?._id
      setEditEnabled(true)
      setFormData({ ...formData, is_editing_by: user, user })

      // Save or update the story
      const apiEndpoint =
        appData.story_Id || id
          ? apiEndpoints.storiesById(appData.story_Id || id)
          : apiEndpoints.stories;
      let response;
      if (!firstApiCalled && !appData.story_Id && !id) {
        url = apiEndpoint
        logsPayload = storyData
        httpMethod = 'POST'

        setIsSaving(true);
        response = await CreateDataApi(apiEndpoint, storyData);
        response?._id ? setFirstApiCalled(true) : setFirstApiCalled(false);
        if (response?._id) setAppData({ story_Id: response?._id, status: response?.status, parent_story_id: response?.parent_story_id, child_story_id: response?.child_story_id });
        if (!isExistingStory || !id) {
          setValue("story_id", response?._id);
        }

        setIsEditingById(user?._id)
      } else if (appData.story_Id || id) {
        url = apiEndpoint
        logsPayload = storyData
        httpMethod = 'PUT'
        logsStoryId = appData?.story_Id || id

        setIsSaving(true);
        response = await UpdateDataApi(apiEndpoint, storyData);

        setAppData({ story_Id: response?._id, status: response?.status, parent_story_id: response?.parent_story_id, child_story_id: response?.child_story_id });
        if (!isExistingStory || !id) {
          setValue("story_id", response?._id);
        }
      }
      if (response) {
        const timeline = await CreateDataApi(apiEndpoints?.timeline, {
          story_id: response?._id,
          action_by: user?._id,
          action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
          action_type: `auto save`,
        });
        await fetchTimeline(`${response?._id}`);
        setIsSaving(false);
        setAppData({ story_Id: response?._id, status: response?.status, parent_story_id: response?.parent_story_id, child_story_id: response?.child_story_id });
        // setStoryStatus(response?.status);
        if (!isExistingStory || !id) {
          setValue("story_id", response?._id);
          // setValue("timeline", response?.timeline);
        }
      }
    } catch (error) {
      console.error("Error auto-saving story", error?.message);

      const errorDetails = {
        message: error.message,
        stack: error.stack
      }

      await saveToLogs({
        source: 'onAutoSaveStory',
        payload: logsPayload,
        error_message: errorDetails,
        url: url,
        http_method: httpMethod,
        story_id: logsStoryId,
        log_type: 'photo story'
      })
    }
  };

  const RemoveEmptyFields = (cards) => {
    const filteredCards = cards.map((card) => ({
      sub_card: card.sub_card.filter((subCard) => !isSubCardEmpty(subCard)),
    }));
    return filteredCards;
  };

  const isSubCardEmpty = (subCard) => {
    return (
      (subCard.title.trim() === "<p><br></p>" || subCard.title.trim() === "") &&
      subCard.attributes.trim() === "" &&
      subCard.actionUrl.trim() === "" &&
      subCard.description.trim() === "" &&
      subCard.question.trim() === "" &&
      subCard.question_attributes.trim() === "" &&
      subCard.answer.trim() === "" &&
      subCard.answer_attributes.trim() === "" &&
      subCard.summary.trim() === "" &&
      subCard.images.trim() === "" &&
      subCard.img_caption.trim() === "" &&
      subCard.img_attribution.trim() === "" &&
      subCard.link_story.trim() === ""
    );
  };
  const hasEmptyField = (cards) => {
    for (const card of cards) {
      for (const subCard of card.sub_card) {
        if (isSubCardEmpty(subCard)) {
          return true;
        }
      }
    }
    return false;
  };
  // useEffect(() => {
  //   if (window.location.hash !== "#/story") {
  //     const intervalId = setInterval(() => {
  //       onAutoSaveStory();
  //     }, 60000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, []);

  useEffect(() => {
    if (loading || isSaving) {
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setIsSaving(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [loading, isSaving]);

  const onUpdateWebStoryStatus = async (status) => {
    const story = getValues();
    story.status = status;
    let cardsAfterRemovedEmptyElement = RemoveEmptyFields(getValues()?.cards);
    setCards(cardsAfterRemovedEmptyElement);
    setValue("cards", cardsAfterRemovedEmptyElement);
    statusRef.current = status;

    setLoading(true);
    const response = await UpdateDataApi(
      `${apiEndpoints.storiesById(story?.story_id || id)}`,
      story
    );

    if (response) {
      // setStoryStatus(response?.status);
      const timeline = await CreateDataApi(apiEndpoints?.timeline, {
        story_id: response?._id,
        action_by: user?._id,
        action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
        action_type: status,
      });
      await fetchTimeline(`${response?._id}`);
      setLoading(false);
      setValue("story_id", response?._id);
    }
  };

  const fetchTimeline = async (storyId) => {
    const response = await GetListApi(`${apiEndpoints.getTimeline}`, {
      story_id: storyId,
    });
    if (response?.timelines) {
      setTimelines(response?.timelines);
    }
  };
  return {
    id,
    tagsList,
    setTagsList,
    selectedTagsList,
    setSelectedTagsList,
    includeSectionList,
    setIncludeSectionList,
    selectedIncludeSection,
    setSelectedIncludeSection,
    authorList,
    setAuthorList,
    selectedAuthorList,
    setSelectedAuthorList,
    HandleSelectChange,
    HandleUploadImage,
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    submittedPage,
    setSubmittedPage,
    pageList,
    setPageList,
    formData,
    setFormData,
    socialCard,
    setSocialCard,
    handleAddElement,
    handleDeleteElement,
    handleSocialCardChange,
    show,
    setShow,
    handleClose,
    handleShow,
    HandleModalDataSubmit,

    totalPages,
    setTotalPages,
    storiesList,
    setStoriesList,
    handleDelete,
    currentPage,
    handlePageChange,
    onUpdate,
    navigate,
    cards,
    setCards,
    HandleGetSection,

    slug,
    setSlug,
    fetchOurStory,
    currentImgId,
    setCurrentImgId,
    imgUrl,
    setImgUrl,
    timelines,
    showTimeline,
    setShowTimeline,
    loading,
    hasEmptyField,
    RemoveEmptyFields,
    getValues,
    loading,
    setLoading,

    isLoading,
    setIsLoading,
    onUpdateWebStoryStatus,
    onUpdateStatus,
    storyStatus,
    setStoryStatus,
    fetchTimeline,
    onAutoSaveStory,
    statusRef,
    isSaving,
    setIsSaving,
    FetchTagsList,
    fetchOurSection,

    editEnabled,
    setEditEnabled,

    isEditingById,
    setIsEditingById,

    populateManageData,
    setPopulateManageData,
  };
};

export default usePhotoStoryhook;
